import { internalApi } from '../../../../internal-api';
import { googleMapsApiKey } from '../../config/config';
import { orderAssistLogger } from '../../logger';
import { AppConfig } from '../../types';

let appConfig: AppConfig;

export const getAppConfig = async (): Promise<AppConfig> => {
  try {
    if (!appConfig) {
      appConfig = await internalApi.orderAssistBff.getAppConfig();
    }
    orderAssistLogger.info('Fetched app configuration successfully', { appConfig });

    return appConfig;
  } catch (error) {
    orderAssistLogger.warning('Failed to fetch app configuration', { error });
    return { googleApiKey: googleMapsApiKey };
  }
};
