import { Entities, StandardEvents } from '@getvim/vim-connect';
import { getVimSpecialtyMetadata } from './vimSpecialty';
import { VimSpecialtyMap } from '../../../components/app/types';
import { orderAssistLogger } from '../../../components/app/logger';
import { isEmpty } from 'lodash-es';

export const convertReferralSpecialty = (
  vimSpecialtyMap?: VimSpecialtyMap,
  specialty?: Entities.Specialty,
): StandardEvents.TransformedSpecialty | undefined => {
  if (!specialty?.description) return specialty;

  if (isEmpty(vimSpecialtyMap)) {
    orderAssistLogger.warning('Vim-Specialties map does not exists. Referral data may be partial');
    return specialty;
  }

  const vimSpecialty = getVimSpecialtyMetadata(vimSpecialtyMap, specialty.description);
  return {
    ...specialty,
    ...vimSpecialty,
  };
};
