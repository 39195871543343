import { themes, ThemeVariablesWrapper } from '@getvim/components-hooks-use-theme';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { ModuleNames } from '@getvim/vim-app-infra';
import React from 'react';
import { featureFlagsClientOrderOptimization } from '../../services/featureFlags';

import { VimCommunicationOAProvider } from '../VimCommunicationOAProvider';
import { OrderAssistAppWrapper } from './components/order-assist-app/OrderAssistAppWrapper';
import { ErrorBoundaryWrapper } from '@getvim/atomic-ui';
import { orderAssistLogger } from './logger';
import { useAnalytics } from '../../analytics/use-analytics';
import { useEnrichLoggerMetadata } from '../../hooks/useEnrichLoggerMetadata';

const App: React.FC = () => {
  useEnrichLoggerMetadata();
  const analyticsReady = useAnalytics();
  return analyticsReady ? (
    <ThemeVariablesWrapper theme={themes.vim}>
      <ErrorBoundaryWrapper
        onRenderError={({ error }) =>
          orderAssistLogger.error('Client application crashed due to render error', {
            error,
          })
        }
        applicationDisplayName="Order Assist"
      >
        <FeatureFlagProvider featureFlagsClient={featureFlagsClientOrderOptimization}>
          <VimCommunicationOAProvider widgetId={ModuleNames.OrderAssistWidget}>
            <OrderAssistAppWrapper />
          </VimCommunicationOAProvider>
        </FeatureFlagProvider>
      </ErrorBoundaryWrapper>
    </ThemeVariablesWrapper>
  ) : null;
};

export default App;
