import { AlignEnum, Button, ColorNameEnum, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  trackStatusScreenCopyButtonClicked,
  trackStatusScreenManualCopy,
  trackStatusScreenViewed,
} from '../../../../../../analytics/referral-request';
import { procedureResponseToAnalyticsProcedure } from '../../../../../../analytics/referral-request/formatters';
import { orderAssistLogger } from '../../../../logger';
import { useClipboard } from '../../../order-assist-app/hooks';
import { ProcedureResponse, ReferralRequestStatus, UpdateCodeStatus } from '../../types';
import { getDifferenceInSeconds } from '../../utils';
import ProcedureStatus from './procedure/ProcedureStatus';
import './ResultStatus.less';
import { getReferralRequestUIString } from '../../strings';
import { getStatusToUiStatus } from '../../ReferraRequestStates';
import { UpdateCodeStatusBanner } from './update-code-status-banner/UpdateCodeStatusBanner';

interface ResultStatusProps {
  onClose: () => void;
  status: ReferralRequestStatus;
  procedures: ProcedureResponse[];
  reason?: string;
  description?: string;
  certificationNumber?: string;
  requestSubmissionTime: number;
  updateCodeStatus: UpdateCodeStatus;
}

const ResultStatus: React.FC<ResultStatusProps> = ({
  onClose,
  status,
  procedures,
  reason,
  certificationNumber,
  requestSubmissionTime,
  updateCodeStatus,
}) => {
  const copy = useClipboard();
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    trackStatusScreenViewed({
      requestStatus: getStatusToUiStatus(status),
      cpts: procedures.map((procedure) => procedureResponseToAnalyticsProcedure(procedure)),
      statusReason: reason,
      certificationId: certificationNumber,
      timeToLoadInSeconds: getDifferenceInSeconds(requestSubmissionTime),
    });
    // TODO fix this in other PR, you can fix this by sending the analytics on each page
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCopyClicked = async (text: string) => {
    try {
      const success = await copy(text);
      if (!success) throw new Error('Copy unsuccessfull');

      trackStatusScreenCopyButtonClicked({
        requestStatus: getStatusToUiStatus(status),
        cpts: procedures.map((procedure) => procedureResponseToAnalyticsProcedure(procedure)),
        statusReason: reason,
        certificationId: certificationNumber,
      });

      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    } catch (error) {
      orderAssistLogger.warning('Failed to copy certificationNumber to clipboard', {
        error,
        certificationNumber,
      });
    }
  };

  const onManualCopy = (text: string) => {
    trackStatusScreenManualCopy({
      requestStatus: getStatusToUiStatus(status),
      cpts: procedures.map((procedure) => procedureResponseToAnalyticsProcedure(procedure)),
      statusReason: reason,
      certificationId: text,
    });
  };

  return (
    <div className="referral-status-container">
      {certificationNumber && <UpdateCodeStatusBanner status={updateCodeStatus} />}
      <img
        className="request-sent-svg"
        src="https://static.getvim.com/img/patient-card-send.svg"
        alt=""
      />
      <Text
        size={SizeEnum['16px']}
        weight={WeightEnum.semibold}
        colorName={ColorNameEnum.theme}
        text={getReferralRequestUIString('referral_result_referral_submitted_title')}
      />
      <Text
        size={SizeEnum['14px']}
        className={classNames(status, 'status')}
        text={getStatusToUiStatus(status)}
      />

      {reason && (
        <Text
          colorName={ColorNameEnum.theme}
          size={SizeEnum['14px']}
          text={`${getReferralRequestUIString('referral_result_status_reason_label')}: `}
        >
          <b className="user-select-text">{reason}</b>
        </Text>
      )}
      {certificationNumber && (
        <>
          <Text
            className="srnLabel"
            text={getReferralRequestUIString('referral_result_status_certification_number_label')}
            align={AlignEnum.left}
            colorName={ColorNameEnum.theme}
            size={SizeEnum['14px']}
          />
          <div className="srnCopyContainer">
            <Text
              className="srnText"
              text={certificationNumber}
              size={SizeEnum['14px']}
              inline
              onCopy={() => onManualCopy(certificationNumber)}
            />
            <Button
              text={isCopied ? 'Copied!' : 'Copy'}
              bgColor={isCopied ? 'gray' : 'default'}
              color="white"
              buttonType="small"
              width="small"
              onClick={() => onCopyClicked(certificationNumber)}
              className="copy-btn"
            />
          </div>
        </>
      )}

      {procedures?.length ? (
        <div className="proceduresStatusContainer">
          {procedures.map((procedure) => (
            <ProcedureStatus {...procedure} />
          ))}
        </div>
      ) : undefined}
      <Button
        onClick={onClose}
        className="closeButton margin-top-10"
        text="Close"
        buttonType="small"
        bgColor="themedOutline"
        width="small"
      />
    </div>
  );
};

export default ResultStatus;
