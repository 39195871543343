import { Insurer } from '@getvim-os/standard';
import { internalApi } from '../../../../internal-api';
import { orderAssistLogger } from '../../logger';
import {
  NuccSearchItem,
  QueryFreeTextResult,
  SearchItem,
  WidgetSource,
  FreeTextEventResult,
  FreeTextQueryPayload,
  FreeTextResult,
  NuccFreeTextResult,
  FreeTextType,
} from '../../types';

const formatNuccFreeTextResult = (
  result: QueryFreeTextResult,
  searchType: FreeTextType.Nucc,
): NuccFreeTextResult[] => {
  const results: NuccFreeTextResult[] = [];
  result[searchType].forEach((searchItem: NuccSearchItem) => {
    results.push({ description: searchItem.description, id: searchItem.id });
  });
  return results;
};

const formatFreeTextResult = (
  result: QueryFreeTextResult,
  searchType: FreeTextType.Icd | FreeTextType.Cpt,
): FreeTextResult[] => {
  const results: FreeTextResult[] = [];
  try {
    result[searchType].forEach((searchItem: SearchItem) => {
      const codes: string[] = [searchItem.code];
      results.push({ codes, description: searchItem.description });
    });
  } catch (error) {
    orderAssistLogger.warning('Failed to parse icd cpt free text response', {
      error,
      response: result,
    });
    throw error;
  }
  return results;
};

export const getFreeTextQuery = async (
  payload: FreeTextQueryPayload,
  _insurer?: Insurer,
  source?: WidgetSource,
): Promise<FreeTextEventResult> => {
  const { freeText, type } = payload;
  const freeTextResults: FreeTextEventResult = { type, result: undefined };

  if (freeText) {
    try {
      const rawResults = await internalApi.orderAssistBff.freeText(
        { freeText, types: [type] },
        source,
      );

      const formattedResults =
        type === FreeTextType.Nucc
          ? formatNuccFreeTextResult(rawResults, type)
          : formatFreeTextResult(rawResults, type);
      orderAssistLogger.info('Finished free text query', { formattedResults, rawResults });
      freeTextResults.result = formattedResults;
    } catch (error) {
      orderAssistLogger.warning('Failed to perform free text query', { error, payload });
    }
  }

  return freeTextResults;
};
