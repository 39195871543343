import { ProductSource } from '@getvim-core-apps/organizations';
import { Common } from '@getvim/vim-connect';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { orderAssistLogger } from '../components/app/logger';
import {
  Patient,
  SourceConfigurationTypes,
  SourceConfigurations,
  WidgetSource,
} from '../components/app/types';
import configMapping, { DEFAULT_MAPPING } from '../utils/sourceMapping';
import { UserConfig } from './use-product-config';

export const calculateSource = (
  patient: Patient | undefined,
  userConfig: UserConfig | undefined,
): WidgetSource | undefined => {
  const activePayerSource = Common.getActivePayerSource(patient, userConfig?.product?.sources);

  if (!Common.isProvider(userConfig?.product?.contentSources)) {
    return activePayerSource;
  }

  orderAssistLogger.warning('Calculated source is provider, returning undefined', {
    contentSources: userConfig?.product?.contentSources,
  });

  return undefined;
};

export const useSourceConfig = (
  shouldFloridaBlueSupportFacility: boolean,
): {
  source: WidgetSource | undefined;
  setSourceConfigParams: (source: WidgetSource | undefined, patient: Patient | undefined) => void;
  patientSourceConfig: { sourceConfig?: SourceConfigurations; patientId?: string };
} => {
  const [source, setSource] = useState<WidgetSource>();
  const [config, setConfig] = useState<SourceConfigurations | undefined>();
  const [patient, setPatient] = useState<Patient>();

  useEffect(() => {
    if (source && patient) {
      const sourceConfig = configMapping.get(source) ?? DEFAULT_MAPPING;
      const configType = SourceConfigurationTypes.AUTHENTICATED;

      if (sourceConfig[configType]) {
        const { defaultDistanceFilter } = sourceConfig[configType];
        const newConfig = {
          ...sourceConfig[configType],
          supportWiderDistanceFilter: sourceConfig[configType].supportWiderDistanceFilter,
          ...(source === ProductSource.BlueCrossBlueShield && shouldFloridaBlueSupportFacility
            ? {
                useSourceFreeText: false,
                supportFacilityName: true,
                supportOrdersButton: true,
              }
            : {}),
          defaultDistanceFilter,
        };

        if (!isEqual(config, newConfig)) {
          orderAssistLogger.info('New source detected, changing source configurations', {
            source,
            configType,
            newConfig,
            oldConfig: config,
          });

          setConfig(newConfig);
        }
      } else {
        orderAssistLogger.warning('Could not find matching source type', {
          source,
          configType,
        });
      }
    } else setConfig(undefined);
  }, [source, patient, config, shouldFloridaBlueSupportFacility]);

  const setSourceConfigParams = useCallback(
    (newSource: WidgetSource | undefined, newPatient: Patient | undefined) => {
      setPatient(newPatient);

      if (newSource !== source) {
        orderAssistLogger.info(`Setting new source - ${newSource}`, { source, newSource });
        setSource(newSource);
      }
    },
    [source, setSource, setPatient],
  );

  return useMemo(
    () => ({
      source,
      setSourceConfigParams,
      patientSourceConfig: { sourceConfig: config, patientId: patient?.patientId },
    }),
    [source, setSourceConfigParams, config, patient?.patientId],
  );
};
