import { Entities, Standard, StandardEvents } from '@getvim/vim-connect';
import AsyncLock from 'async-lock';
import { isEqual, omit } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useModifyAppViewState, UserConfig } from '../../../../../../hooks';
import { featureFlagsClientOrderOptimization } from '../../../../../../services/featureFlags';
import { isPatientEligible } from '../../../../../../utils/patientEligibility';
import { orderAssistLogger } from '../../../../logger';
import { ICD, Patient, WidgetSource } from '../../../../types';
import { analyticsManager } from '../../../../../../analytics/analyticsManager';
import { trackAppEnabled } from '../../../../../../analytics/trackAppEnabled';
import { EhrContext } from '../../../../../../analytics/types';
import { SUPPORTED_ORDER_TYPES } from '../../../../../../consts';
import { useEhrState } from '../../../../../../stores/ehr-state/EhrState.store';
import { EhrStateActionType } from '../../../../../../stores/ehr-state/ehrState.types';
import { AppActionType } from '../../reducers/appReducer';
import { SearchActionType } from '../../reducers/searchReducer';
import { OnPopulateOrderAssistCallbackProps } from '../usePopulateOrderAssist';
import { isVimOsAppMode } from '../../../../../../vim-os-sdk-migration';
import { useRuntimeEventHandlers } from './useRuntimeEventHandlers';
import { useVimOsSdkEventHandlers } from './useSdkEventHandlers';
import { useOrderAssistAppFeatureFlags } from '../../OrderAssistFFWrapper';
import { OrderAssistEventHandlers, UseEventHandlersInput } from './types';

const eventHandlerLock = new AsyncLock({ maxOccupationTime: 35_000 });

const eventLockHandler = async (handler: () => Promise<void> | void) => {
  try {
    await eventHandlerLock.acquire('eventsLock', async () => {
      await handler();
    });
  } catch (error) {
    orderAssistLogger.warning('Error occurred on handleEvents lock', { error });
  }
};

const LABS_VIM_SPECIALTY = {
  id: 'f_149_0',
  description: 'Clinical Medical Laboratory',
};
const DI_VIM_SPECIALTY = {
  id: 'f_84_16',
  description: 'Clinic/Center - Radiology',
};

const useEventHandlersHook = isVimOsAppMode ? useVimOsSdkEventHandlers : useRuntimeEventHandlers;

export const useEventHandlers = ({
  appDispatch,
  searchDispatch,
  onReferralViewedAnalytics,
  onReferralClosedAnalytics,
  onPopulateOrderAssist,
  userConfig,
  patient,
  referral,
  order: existingOrder,
  patientSourceConfig,
  organizationId,
  lastUpdatedReferralId,
  handleNotificationAction,
}: UseEventHandlersInput) => {
  const [handleModifyAppView] = useModifyAppViewState();
  const { dispatch: ehrDispatch } = useEhrState();
  const [appEnabled, setAppEnabled] = useState<boolean>(false);
  const [currentOrder, setCurrentOrder] = useState<StandardEvents.OrderViewedPayload | undefined>();
  const [currentReferral, setCurrentReferral] = useState<
    Standard.Events.TransformedReferralViewedPayload | undefined
  >();

  const { shouldTrackAppEnabledAnalytics, shouldUseSourceVaultTokens } =
    useOrderAssistAppFeatureFlags();

  const onPatientInContextEvent = useCallback(
    async (data: Patient) => {
      const handler = async () => {
        orderAssistLogger.info('Start handling patientInContext event', {
          data,
        });
        const patientInContext: Patient | undefined = data;
        if (shouldUseSourceVaultTokens && patientInContext.patientId === patient?.patientId) {
          orderAssistLogger.debug('Patient already in context, return');
          return;
        } else {
          const patientInContextNoTokens = omit(patientInContext, 'memberTokens');
          const patientNoTokens = omit(patient, 'memberTokens');

          if (isEqual(patientInContextNoTokens, patientNoTokens)) {
            if (isEqual(patientInContext.memberTokens, patient?.memberTokens)) {
              return;
            }

            orderAssistLogger.debug('Patient already in context, updating member tokens only');
            appDispatch({
              type: AppActionType.ON_UPDATE_MEMBER_TOKEN,
              payload: { memberTokens: patientInContext.memberTokens },
            });
            return;
          }
        }

        appDispatch({ type: AppActionType.RESET_STATE });
        searchDispatch({ type: SearchActionType.RESET_STATE });
        appDispatch({
          type: AppActionType.ON_PATIENT_IN_CONTEXT_EVENT,
          payload: { patient: patientInContext },
        });

        orderAssistLogger.info('Finish handling patientInContext event');
      };

      await eventLockHandler(handler);
    },
    // To trigger only when patient-id changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appDispatch, searchDispatch, patient?.patientId, userConfig],
  );

  const onPatientOutOfContextEvent = useCallback(async () => {
    const handler = () => {
      orderAssistLogger.info('Start handling patientOutOfContext event');

      appDispatch({ type: AppActionType.RESET_STATE });
      searchDispatch({ type: SearchActionType.RESET_STATE });
      ehrDispatch({ type: EhrStateActionType.RESET_STATE });
      setAppEnabled(false);
      handleModifyAppView({ enable: false });
      setCurrentOrder(undefined);
      setCurrentReferral(undefined);
      orderAssistLogger.info('Finish handling patientOutOfContext event');
    };

    await eventLockHandler(handler);
  }, [appDispatch, searchDispatch, ehrDispatch, handleModifyAppView]);

  const handleOrderViewed = useCallback(
    (data: StandardEvents.OrderViewedPayload) => {
      if (data.loading || !patientSourceConfig?.sourceConfig?.supportOrdersButton) return;

      const { order } = data;
      const { id, icd, cpts } = order;

      // Reset form when changing orders
      if (id !== existingOrder?.id) {
        searchDispatch({ type: SearchActionType.RESET_STATE });
      }

      // Populate order-assist with new data from event
      if (!isEqual(existingOrder, { id, icd, cpts })) {
        let vimSpecialty: StandardEvents.TransformedSpecialty | undefined;
        switch (order?.type) {
          case Standard.Entities.OrderType.LAB:
            vimSpecialty = {
              description: LABS_VIM_SPECIALTY.description,
              vimSpecialty: LABS_VIM_SPECIALTY,
            };
            break;
          case Standard.Entities.OrderType.DI:
            vimSpecialty = {
              description: DI_VIM_SPECIALTY.description,
              vimSpecialty: DI_VIM_SPECIALTY,
            };
            break;
          default:
            orderAssistLogger.warning('order type is not supported, skipping population', {
              order,
            });
            break;
        }
        const dataToPopulate: OnPopulateOrderAssistCallbackProps = {
          icd,
          cpt: cpts,
          specialty: vimSpecialty,
        };
        onPopulateOrderAssist(dataToPopulate);

        appDispatch({
          type: AppActionType.ON_ORDER_VIEWED_EVENT,
          payload: { id, icd, cpts },
        });
      }
    },
    [
      patientSourceConfig?.sourceConfig?.supportOrdersButton,
      existingOrder,
      searchDispatch,
      onPopulateOrderAssist,
      appDispatch,
    ],
  );

  const onOrderViewedEvent = useCallback(
    async (data: StandardEvents.OrderViewedPayload) => {
      const handler = async () => {
        ehrDispatch({ type: EhrStateActionType.SET_ORDER, payload: data.order });

        if (data.order?.type === Standard.Entities.OrderType.PROCEDURE) {
          orderAssistLogger.info('skipping order viewed handling', {
            reason: 'order type is equal to: Procedure',
          });
          return;
        }

        const handleSupportedOrderTypesOnly = await featureFlagsClientOrderOptimization.getFlag({
          flagName: 'orderAssist.handleSupportedOrderTypesOnly',
          defaultValue: false,
          flagContext: {
            organizationId: userConfig?.organization?.id,
            adapterName: userConfig?.adapterName,
          },
        });

        if (!data.order?.type) {
          orderAssistLogger.debug('order type is undefined, skipping orderViewed handling');
          return;
        }

        const shouldSkipOrderViewedHandle =
          handleSupportedOrderTypesOnly && !SUPPORTED_ORDER_TYPES.includes(data.order.type);

        if (shouldSkipOrderViewedHandle) {
          orderAssistLogger.debug('skipping order viewed handling', {
            reason: 'order type is not supported',
            orderType: data.order.type,
          });
          return;
        }
        orderAssistLogger.info('Start handling orderViewed event', {
          supportOrdersButton: patientSourceConfig?.sourceConfig?.supportOrdersButton,
        });
        analyticsManager.setEhrContext(EhrContext.ORDER);
        setCurrentOrder(data);
        orderAssistLogger.info('Finish handling orderViewed event', {
          supportOrdersButton: patientSourceConfig?.sourceConfig?.supportOrdersButton,
        });
      };

      await eventLockHandler(handler);
    },
    [
      patientSourceConfig?.sourceConfig?.supportOrdersButton,
      ehrDispatch,
      userConfig?.organization?.id,
      userConfig?.adapterName,
    ],
  );

  const onOrderClosedEvent = useCallback(async () => {
    const handler = async () => {
      orderAssistLogger.info('Start handling orderClosed event');

      ehrDispatch({ type: EhrStateActionType.CLEAR_ORDER });
      analyticsManager.setEhrContext(EhrContext.OUT_OF_CONTEXT);
      searchDispatch({ type: SearchActionType.RESET_STATE });
      appDispatch({ type: AppActionType.ON_ORDER_CLOSED_EVENT });

      orderAssistLogger.info('Finish handling orderClosed event');
    };

    await eventLockHandler(handler);
  }, [appDispatch, ehrDispatch, searchDispatch]);

  const onOrdersViewedEvent = useCallback(async () => {
    const handler = () => {
      orderAssistLogger.info('Start handling ordersViewed event');
      analyticsManager.setEhrContext(EhrContext.ORDER);
      orderAssistLogger.info('Finish handling ordersViewed event');
    };

    await eventLockHandler(handler);
  }, []);

  const onOrdersClosedEvent = useCallback(async () => {
    const handler = async () => {
      orderAssistLogger.info('Start handling ordersClosed event');
      analyticsManager.setEhrContext(EhrContext.OUT_OF_CONTEXT);
      searchDispatch({ type: SearchActionType.RESET_STATE });
      appDispatch({ type: AppActionType.ON_ORDER_CLOSED_EVENT });
      orderAssistLogger.info('Finish handling ordersClosed event');
    };
    await eventLockHandler(handler);
  }, [appDispatch, searchDispatch]);

  const handleReferralViewed = useCallback(
    async (data: Standard.Events.TransformedReferralViewedPayload) => {
      analyticsManager.setEhrContext(EhrContext.REFERRAL);
      ehrDispatch({ type: EhrStateActionType.SET_REFERRAL, payload: data });

      const patientInContext = data.patient;

      if (
        !shouldUseSourceVaultTokens &&
        patientInContext?.patientId &&
        patient?.patientId === patientInContext.patientId
      ) {
        appDispatch({
          type: AppActionType.ON_UPDATE_MEMBER_TOKEN,
          payload: { memberTokens: patientInContext.memberTokens },
        });
      }

      onReferralViewedAnalytics(data);

      const { vimReferralId, diagnosis, specialty, referringProvider, targetProvider } = data;

      const incomingReferralToDispatch = {
        vimReferralId,
        diagnosis,
        specialty,
        referringProvider,
        targetProvider,
        ehrNativeSpecialty: data.specialty?.description,
      };

      // Reset form when changing referrals
      if (vimReferralId !== referral?.vimReferralId) {
        searchDispatch({ type: SearchActionType.RESET_STATE });
      }

      // Populate order-assist with new data from event
      if (!isEqual(referral, incomingReferralToDispatch)) {
        const icd: ICD[] =
          diagnosis?.map(({ code, description: name }) => ({
            code,
            name,
          })) ?? [];

        const ignoreLastUpdatedReferralOnPopulateFF =
          await featureFlagsClientOrderOptimization.getFlag({
            flagName: 'ignoreLastUpdatedReferralOnPopulate',
            defaultValue: false,
          });

        // Avoid populating referrals data if we updated this referral already
        if (
          !ignoreLastUpdatedReferralOnPopulateFF ||
          !lastUpdatedReferralId ||
          vimReferralId !== lastUpdatedReferralId
        ) {
          const dataToPopulate: OnPopulateOrderAssistCallbackProps = {
            icd,
            ehrNativeSpecialty: incomingReferralToDispatch.ehrNativeSpecialty,
            specialty,
            referringProvider,
            vimReferralId,
            vimPatientId: patientInContext?.vimPatientId,
          };

          orderAssistLogger.info('Populating data for referral', {
            vimReferralId,
          });

          onPopulateOrderAssist(dataToPopulate);

          appDispatch({
            type: AppActionType.ON_REFERRAL_VIEWED_EVENT,
            payload: incomingReferralToDispatch,
          });
        } else if (ignoreLastUpdatedReferralOnPopulateFF && lastUpdatedReferralId) {
          orderAssistLogger.info('Populate data skipped, referral already updated', {
            lastUpdatedReferralId,
          });
        }
      }
    },
    [
      appDispatch,
      ehrDispatch,
      lastUpdatedReferralId,
      onPopulateOrderAssist,
      onReferralViewedAnalytics,
      patient?.patientId,
      referral,
      searchDispatch,
    ],
  );

  const onReferralViewedEvent = useCallback(
    async (data: StandardEvents.TransformedReferralViewedPayload) => {
      if (data.loading) return;

      const handler = async () => {
        orderAssistLogger.info('Start handling referralViewed event', {
          patientId: patient?.patientId,
        });

        setCurrentReferral(data);

        orderAssistLogger.info('Finish handling referralViewed event', {
          patientId: patient?.patientId,
        });
      };

      await eventLockHandler(handler);
    },
    [handleReferralViewed, patient?.patientId, organizationId],
  );

  const onReferralClosedEvent = useCallback(async () => {
    const handler = () => {
      orderAssistLogger.info('Start handling referralClosed event');
      analyticsManager.setEhrContext(EhrContext.OUT_OF_CONTEXT);
      ehrDispatch({ type: EhrStateActionType.CLEAR_REFERRAL });

      onReferralClosedAnalytics();

      searchDispatch({ type: SearchActionType.RESET_STATE });
      appDispatch({ type: AppActionType.ON_REFERRAL_CLOSED_EVENT });

      setCurrentReferral(undefined);

      orderAssistLogger.info('Finish handling referralClosed event');
    };

    await eventLockHandler(handler);
  }, [appDispatch, ehrDispatch, onReferralClosedAnalytics, searchDispatch]);

  useEffect(() => {
    const orderPatientId = currentOrder?.patient?.patientId;
    if (!patientSourceConfig?.sourceConfig || !patientSourceConfig?.patientId || !orderPatientId)
      return;

    if (orderPatientId !== patientSourceConfig.patientId) {
      setCurrentOrder(undefined);
      return;
    }

    handleOrderViewed(currentOrder);

    setCurrentOrder(undefined);
  }, [
    patientSourceConfig?.sourceConfig,
    patientSourceConfig?.patientId,
    currentOrder,
    handleOrderViewed,
  ]);

  useEffect(() => {
    const referralPatientId = currentReferral?.patient?.patientId;
    if (!referralPatientId || !patient?.patientId || !appEnabled) return;

    if (referralPatientId !== patient?.patientId) {
      setCurrentReferral(undefined);
      return;
    }

    handleReferralViewed(currentReferral);

    setCurrentReferral(undefined);
  }, [patient, currentReferral, handleReferralViewed, appEnabled]);

  useEffect(() => {
    const disableApp: () => void = () => {
      setAppEnabled(false);
      handleModifyAppView({
        enable: false,
      });
    };

    const modifyAppVisibility = async (currentPatient: Patient | undefined, config: UserConfig) => {
      const isEligible = currentPatient
        ? await isPatientEligible(currentPatient, config, shouldUseSourceVaultTokens)
        : false;

      if (!currentPatient || !isEligible) {
        orderAssistLogger.info('Patient is not eligible, calling handle patient out of context', {
          patient: currentPatient?.vimPatientId,
        });
        return disableApp();
      }

      if (
        !shouldUseSourceVaultTokens &&
        !isEqual(patient?.memberTokens, currentPatient?.memberTokens)
      ) {
        appDispatch({
          type: AppActionType.ON_UPDATE_MEMBER_TOKEN,
          payload: { memberTokens: currentPatient?.memberTokens },
        });
      }

      if (!shouldTrackAppEnabledAnalytics) {
        handleModifyAppView({
          enable: true,
          reason: Entities.UIElements.ModifyReason.PatientEligible,
        });
        return;
      }

      if (!appEnabled) {
        handleModifyAppView({
          enable: true,
          reason: Entities.UIElements.ModifyReason.PatientEligible,
        });

        setAppEnabled(true);
        trackAppEnabled({
          app_source_enabled: config?.product?.contentSources as WidgetSource[],
        });
      }
    };

    if (userConfig.product) {
      modifyAppVisibility(patient, userConfig);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userConfig,
    handleModifyAppView,
    appDispatch,
    patient?.patientId,
    appEnabled,
    setAppEnabled,
    shouldTrackAppEnabledAnalytics,
  ]);

  const onRuntimeNotificationActionEvent = useCallback(
    async (data: StandardEvents.NotificationActionButtonClick) => {
      const handler = () => {
        orderAssistLogger.info('Start handling notificationActionButtonClick event');

        const { action, notificationId } = data;
        handleNotificationAction({ action, notificationId });

        orderAssistLogger.info('Start handling notificationActionButtonClick event');
      };

      await eventLockHandler(handler);
    },
    [handleNotificationAction],
  );

  const eventHandlers: OrderAssistEventHandlers = {
    onPatientInContextEvent,
    onPatientOutOfContextEvent,
    onOrderViewedEvent,
    onOrderClosedEvent,
    onOrdersViewedEvent,
    onOrdersClosedEvent,
    onReferralViewedEvent,
    onReferralClosedEvent,
    onRuntimeNotificationActionEvent,
  };

  useEventHandlersHook(eventHandlers);
};
