import { AppPatient, Patient, WidgetSource } from '../components/app/types';

import { calculateSource } from '../hooks/useSourceConfig';
import { extractMemberTokenForSource } from './memberToken';

import { orderAssistLogger } from '../components/app/logger';
import { UserConfig } from '../hooks';
import { logic } from '../components/app/logic';
import { extractPatientSourceVaultToken } from '@getvim-core-apps/organizations';

export const isPatientEligibleForSource = (
  patient: AppPatient | undefined,
  source: WidgetSource | undefined,
  shouldUseSourceVaultTokens: boolean,
): boolean => {
  return (
    !!source &&
    (shouldUseSourceVaultTokens
      ? !!extractPatientSourceVaultToken(patient?.patientSourceVaultTokens, source)
          ?.sourceVaultToken
      : !!extractMemberTokenForSource(patient?.memberTokens, source))
  );
};

export const isPatientEligible = async (
  patient: Patient | undefined,
  userConfig: UserConfig | undefined,
  shouldUseSourceVaultTokens: boolean,
): Promise<boolean> => {
  if (!patient) {
    orderAssistLogger.warning('Patient is not defined');
    return false;
  }
  if (!userConfig) {
    orderAssistLogger.warning('userConfig is undefined, cannot check eligibility for patient');
    return false;
  }

  const supportedPersonCapabilites = await logic.supportedPersonCapabilites(
    patient,
    userConfig,
    shouldUseSourceVaultTokens,
  );
  if (!supportedPersonCapabilites) {
    return false;
  }

  const source = calculateSource(patient, userConfig);
  return isPatientEligibleForSource(patient, source, shouldUseSourceVaultTokens);
};
