import { FlowManager } from '@getvim/flow-manager';
import React, { useCallback } from 'react';
import {
  referralRequestAnalyticsClient,
  trackReferralRequestWriteback,
} from '../../../../analytics/referral-request';
import './index.less';
import { ReferralRequestLoader } from './loader';
import { ReferralRequiredStep } from './steps/ReferralRequiredStep';
import { RequestingProviderStep } from './steps/RequestingProviderStep';
import { ResultStep } from './steps/ResultStep';
import { ServiceInformationStep } from './steps/ServiceInformationStep';
import {
  ReferralContext,
  ReferralFlowManagerProps,
  ReferralRequestFormSubmission,
  ReferralRequestStatus,
  ReferralStep,
  ReferralSteps,
  RequestReferralInput,
  UpdateCodeStatus,
} from './types';
import { buildReferralRequestInput } from './formatters';
import { orderAssistLogger } from '../../logger';
import { submitReferralRequest } from '../../logic/actions/referralRequest';
import { useWriteBack } from '../order-assist-app/hooks';
import { useOrderAssistAppFeatureFlags } from '../order-assist-app/OrderAssistFFWrapper';

const initialStepsStatus: ReferralContext['stepStatus'] = {
  REQUESTING_PROVIDER: {
    text: '01',
    isActive: true,
    isDone: false,
  },
  SERVICE_INFORMATION: {
    text: '02',
    isActive: false,
    isDone: false,
  },
};

const WRITEBACK_CODE_STATUSES = new Set([
  ReferralRequestStatus.APPROVED,
  ReferralRequestStatus.APPROVED_AND_MODIFIED,
]);

export const ReferralRequestFlowManager: React.FC<ReferralFlowManagerProps> = ({
  backToResults,
  onFreeTextQuery,
  targetProvider,
  memberToken,
  sourceVaultToken,
  source,
  requestingProvider,
  serviceInformation,
  onResponse,
  shouldUpdateAuthCode,
}) => {
  const { shouldUseSourceVaultTokens } = useOrderAssistAppFeatureFlags();
  const { writeAuthCode } = useWriteBack();

  const referralRequiredStep = useCallback(() => {
    return <ReferralRequiredStep backToResults={backToResults} />;
  }, [backToResults]);

  const requestingProviderStep = useCallback(() => {
    return <RequestingProviderStep onFreeTextQuery={onFreeTextQuery} />;
  }, [onFreeTextQuery]);

  const sendRequestToAvaility = useCallback(
    async (serviceInformationData, context): Promise<ReferralRequestFormSubmission> => {
      let updateCodeStatus = UpdateCodeStatus.NOT_SUPPORTED;

      try {
        if (shouldUseSourceVaultTokens && !sourceVaultToken) {
          orderAssistLogger.warning('missing source vault token when sending referral request');
          return { success: false, updateCodeStatus };
        }

        if (!shouldUseSourceVaultTokens && !memberToken) {
          orderAssistLogger.warning('missing member token when sending referral request');
          return { success: false, updateCodeStatus };
        }

        const request: RequestReferralInput = buildReferralRequestInput({
          serviceInformationData,
          targetProvider,
          requestingProvider: context.requestingProvider,
          memberToken,
          sourceVaultToken,
          shouldUseSourceVaultTokens,
        });
        const response = await submitReferralRequest(request, source);
        onResponse({
          requestingProvider: context.requestingProvider,
          targetProvider,
          serviceInformation: serviceInformationData,
          response,
        });

        if (
          shouldUpdateAuthCode &&
          response?.certificationNumber &&
          WRITEBACK_CODE_STATUSES.has(response.status)
        ) {
          const updateSuccess = (await writeAuthCode(response.certificationNumber)).success;
          updateCodeStatus = updateSuccess ? UpdateCodeStatus.SUCCESS : UpdateCodeStatus.FAILURE;
          trackReferralRequestWriteback({
            certificationId: response.certificationNumber,
            status: updateCodeStatus,
            requestStatus: response.status,
            statusReason: response.reason,
          });
        } else {
          orderAssistLogger.info('Skipping update referral auth code', {
            shouldUpdateAuthCode,
            authCode: response?.certificationNumber,
            responseStatus: response?.status,
          });
        }

        return { success: true, response, updateCodeStatus };
      } catch (error) {
        orderAssistLogger.warning('error sending request to availity', { error });
        return { success: false, updateCodeStatus };
      }
    },
    [targetProvider, source, onResponse, shouldUpdateAuthCode, writeAuthCode, memberToken],
  );

  const serviceInformationStep = useCallback(() => {
    return (
      <ServiceInformationStep
        onFreeTextQuery={onFreeTextQuery}
        onSubmitForm={sendRequestToAvaility}
      />
    );
  }, [onFreeTextQuery, sendRequestToAvaility]);

  const loadingStep = useCallback(() => {
    return <ReferralRequestLoader />;
  }, []);

  const resultStep = useCallback(() => {
    return <ResultStep backToResults={backToResults} />;
  }, [backToResults]);

  return (
    <div>
      <FlowManager<ReferralContext, ReferralSteps>
        initialContext={{
          stepStatus: initialStepsStatus,
          requestingProvider,
          serviceInformation,
          isLoading: false,
        }}
        onStepChange={({ nextStep }) => {
          referralRequestAnalyticsClient.setCurrentScreen(nextStep);
        }}
        initialStep={ReferralStep.REFERRAL_REQUIRED}
        steps={{
          [ReferralStep.REFERRAL_REQUIRED]: referralRequiredStep,
          [ReferralStep.REQUESTING_PROVIDER]: requestingProviderStep,
          [ReferralStep.SERVICE_INFORMATION]: serviceInformationStep,
          [ReferralStep.LOADING]: loadingStep,
          [ReferralStep.RESULT]: resultStep,
        }}
      />
    </div>
  );
};
