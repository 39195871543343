import { extractPatientSourceVaultToken, ProductSource } from '@getvim-core-apps/organizations';
import { orderAssistLogger } from '../../logger';
import { logic } from '../../logic';
import { SendOutcomeFunction } from '../../types';
import { isProviderResult } from '../../utils/general';
import { extractMemberTokenForSource } from '../../../../utils/memberToken';

export const sendUHCOutcome: SendOutcomeFunction = ({
  item,
  isAlternative,
  selectedLocation,
  currentSearchState,
  extraInfo,
  patient,
  shouldUseSourceVaultTokens,
}) => {
  const token = shouldUseSourceVaultTokens
    ? extractPatientSourceVaultToken(
        patient?.patientSourceVaultTokens,
        ProductSource.UnitedHealthCare,
      )?.sourceVaultToken
    : extractMemberTokenForSource(patient?.memberTokens, ProductSource.UnitedHealthCare);

  if (!token) return;

  if (!currentSearchState.specialty) {
    orderAssistLogger.warning('Specialty cannot be undefined for sendOutcome query', {
      currentSearchState,
    });
    return;
  }

  logic.sendOutcome(
    {
      isProvider: isProviderResult(item),
      cpts: currentSearchState.cpt,
      isAlternative,
      location: selectedLocation,
      item,
      specialty: currentSearchState.specialty,
      extraInfo,
    },
    token,
    shouldUseSourceVaultTokens,
  );
};
