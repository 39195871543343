import dayjs from 'dayjs';
import { compact, startCase } from 'lodash-es';
import getInsurerLogoURL from '../../../../../utils/getInsurerLogoURL';
import getStatusBadgeURL from '../../../../../utils/getStatusBadgeURL';
import {
  placeOfServiceMapping,
  serviceTypeMapping,
} from '../../../components/referral-request/service-information/consts';
import {
  PdfProcedure,
  PdfRenderingProvider,
  PdfRequestingProvider,
  ProcedureResponse,
  ReferralRequestExportContext,
  ReferralRequestPDFInput,
  RequestingProvider,
} from '../../../components/referral-request/types';
import { orderAssistLogger } from '../../../logger';
import { Patient, WriteBackProvider } from '../../../types';

const DATE_FORMAT = 'MMM D YYYY';

const formatClinicalDate = (
  date: string | number | Date | dayjs.Dayjs | undefined,
): string | null => {
  if (!date) return null;
  return dayjs(date).format(DATE_FORMAT);
};

const formatPersonName = (firstName?: string, lastName?: string): string =>
  startCase(`${firstName || '---'} ${lastName || '---'}`.toLowerCase());

const formatProcedureResponseToPdfProcedure = (procedure: ProcedureResponse): PdfProcedure => ({
  code: `${procedure.cpt.code} - ${procedure.cpt.description}`,
  message: procedure.notes[0].message,
  quantity: `${procedure.serviceQuantity} ${procedure.quantityType}`,
  statusBadge: getStatusBadgeURL(procedure.status) || '',
});

const formatWritebackProviderToPdfProvider = (
  provider: WriteBackProvider,
): PdfRenderingProvider => ({
  name: formatPersonName(provider.demographics.firstName, provider.demographics.lastName),
  npi: provider.npi,
  specialties: compact(provider.nativeSpecialties?.map((specialty) => specialty.description)),
  address: provider.organizations?.[0].address?.fullAddress,
  phoneNumber: provider.organizations?.[0].contact_info?.mobilePhoneNumber,
  faxNumber: provider.organizations?.[0].contact_info?.faxNumber,
});

const formatRequestingProviderToPdfProvider = (
  provider: RequestingProvider,
): PdfRequestingProvider => ({
  name: formatPersonName(provider.firstName, provider.lastName),
  npi: provider.npi,
  speciality: provider.specialty?.description,
  address: provider.address?.fullAddress,
});

export const referralRequestPdfFormatters = {
  formatFilename: (patient?: Patient): string => {
    const date = dayjs().format('MM-DD-YY');
    const prefix = patient?.demographics.firstName ? `${patient?.demographics.firstName} - ` : '';
    return `${prefix}Order Assist Referral Request - ${date}.pdf`;
  },
  formatInput: (exportContext: ReferralRequestExportContext): ReferralRequestPDFInput => {
    const {
      patient,
      requestingProvider,
      serviceInformation,
      targetProvider,
      response,
      userConfig,
    } = exportContext;
    if (
      !patient ||
      !requestingProvider ||
      !serviceInformation ||
      !targetProvider ||
      !response ||
      !userConfig
    ) {
      throw new Error('PDF generation input build failed - missing one of the required parameters');
    }

    if (!userConfig?.vimConnectUser) {
      throw new Error('PDF generation input build failed - vimConnectUser is undefined');
    }
    const datasourceLogo = patient?.insurance?.insurer
      ? getInsurerLogoURL(patient?.insurance?.insurer)
      : undefined;

    const input: ReferralRequestPDFInput = {
      clinicName: userConfig?.organization.name,
      exportingUser: `${userConfig?.vimConnectUser.firstName} ${userConfig?.vimConnectUser.lastName}`,
      dateOfExporting: dayjs().format(DATE_FORMAT),
      datasourceLogo,
      status: {
        badge: getStatusBadgeURL(response.status),
        statusReason: response.reason,
        certificationNumber: response.certificationNumber,
        certificationEffectiveDate: formatClinicalDate(response.certificationEffectiveDate),
        certificationExpirationDate: formatClinicalDate(response.certificationExpirationDate),
      },
      patient: {
        name: formatPersonName(patient.demographics.firstName, patient.demographics.lastName),
        dateOfBirth: dayjs(patient.demographics.dateOfBirth).format('MMM D, YYYY'),
        age: dayjs().diff(patient.demographics.dateOfBirth, 'y'),
      },
      memberInformation: {
        name: formatPersonName(patient.demographics.firstName, patient.demographics.lastName),
        address: patient.demographics.address?.fullAddress,
        memberId: response.memberInformation?.memberId,
        subscriberName: formatPersonName(
          response.memberInformation?.subscriberName?.firstName,
          response.memberInformation?.subscriberName?.lastName,
        ),
        subscriberRelationship: response.memberInformation?.subscriberRelationship,
      },
      serviceInformation: {
        serviceType: serviceInformation.serviceType
          ? `${serviceInformation.serviceType} - ${
              serviceTypeMapping[serviceInformation.serviceType]
            }`
          : '',
        serviceLevel: response.serviceInformation?.serviceLevel,
        placeOfService: serviceInformation.placeOfService
          ? `${serviceInformation.placeOfService} - ${
              placeOfServiceMapping[serviceInformation.placeOfService]
            }`
          : '',
        fromDate: formatClinicalDate(response.fromDate),
        toDate: formatClinicalDate(response.toDate),
        quantity: `${response.serviceInformation?.quantity} ${response.serviceInformation?.quantityType}`,
      },
      diagnosisCodes: serviceInformation.diagnoses?.map(
        (diagnosis) => `${diagnosis.code} - ${diagnosis.description}`,
      ),
      procedures: response.procedures?.map((procedure) =>
        formatProcedureResponseToPdfProcedure(procedure),
      ),
      requestingProvider: formatRequestingProviderToPdfProvider(requestingProvider),
      renderingProvider: formatWritebackProviderToPdfProvider(targetProvider),
    };
    return input;
  },
};
