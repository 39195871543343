import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { useEffect, useMemo, useState } from 'react';
import { orderAssistLogger } from '../../components/app/logger';
import {
  AnalyticsManager,
  ExportAnalyticsManager,
  ReferralRequestAnalyticsManager,
} from '../clients';

export const useSdkAnalytics = () => {
  const { trackAnalytics } = useApi();
  const [analyticsReady, setAnalyticsReady] = useState<boolean>(false);

  const analyticsClient = useMemo(
    () => ({
      trackAnalytics,
    }),
    [trackAnalytics],
  );

  useEffect(() => {
    if (!analyticsClient?.trackAnalytics) {
      orderAssistLogger.warning('analyticsClient not initialized yet');
      return;
    }

    AnalyticsManager.setAnalyticsClient(analyticsClient);
    ExportAnalyticsManager.setAnalyticsClient(analyticsClient);
    ReferralRequestAnalyticsManager.setAnalyticsClient(analyticsClient);
    setAnalyticsReady(true);
  }, [analyticsClient]);

  return analyticsReady;
};
