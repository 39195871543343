import { VimSDKProvider } from '@getvim/internal-vim-os-sdk/react';
import { isVimOsAppMode } from './vim-os-sdk-migration';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { featureFlagsClientOrderOptimization } from './services/featureFlags';
import { StatesProvider } from './stores';
import sdkOptions from './sdkOptions';
import { TelemetryProvider } from '@getvim/opentelemetry-sdk/react';
import { widgetId } from './consts';
import { AppEnv } from './utils/appEnv';

const OASdkWrapper = ({ children }) =>
  isVimOsAppMode ? <VimSDKProvider {...sdkOptions}>{children}</VimSDKProvider> : children;

const appEnv: AppEnv = (window as any).$vim_environment?.APP_ENV;

ReactDOM.render(
  <TelemetryProvider
    options={{
      serviceName: widgetId,
      env: appEnv,
      enableLogs: true,
      enableMetrics: true,
      enableTraces: true,
    }}
  >
    <OASdkWrapper>
      <FeatureFlagProvider featureFlagsClient={featureFlagsClientOrderOptimization}>
        <React.StrictMode>
          <StatesProvider>
            <App />
          </StatesProvider>
        </React.StrictMode>
      </FeatureFlagProvider>
    </OASdkWrapper>
  </TelemetryProvider>,
  document.getElementById('root'),
);
