import { internalApi } from '../../../../internal-api';
import { AppPatient, SupportPersonCapabilityInput } from '../../types';
import { UserConfig } from '../../../../hooks';
import { calculateSource } from '../../../../hooks/useSourceConfig';
import { extractMemberTokenForSource } from '../../../../utils/memberToken';
import { orderAssistLogger } from '../../logger';
import { extractPatientSourceVaultToken } from '@getvim-core-apps/organizations';

export const supportedPersonCapabilites = async (
  patient: AppPatient,
  config: UserConfig,
  shouldUseSourceVaultTokens: boolean,
): Promise<boolean> => {
  const source = calculateSource(patient, config);
  if (!source) {
    return false;
  }

  let token: string | undefined = undefined;
  if (shouldUseSourceVaultTokens) {
    token = extractPatientSourceVaultToken(
      patient.patientSourceVaultTokens,
      source,
    )?.sourceVaultToken;
    if (!token) {
      orderAssistLogger.info(
        'Failed to extract sourceVaultToken for source in supportedPersonCapabilites query',
        {
          sourceVaultToken: patient.patientSourceVaultTokens,
          source,
        },
      );
      return false;
    }
  } else {
    token = extractMemberTokenForSource(patient.memberTokens, source);
    if (!token) {
      orderAssistLogger.info(
        'Failed to extract member token for source in supportedPersonCapabilites query',
        {
          memberTokens: patient.memberTokens,
          source,
        },
      );
      return false;
    }
  }

  const input: SupportPersonCapabilityInput = {
    ...(shouldUseSourceVaultTokens
      ? { anonymizedPatient: token }
      : {
          encryptedPatientDetails: {
            encryptedIdentifyInput: token,
          },
        }),
    user: {
      id: config.vimConnectUser?.externalId || '',
      userOrganization: {
        id: config.organization.id.toString(),
        name: config.organization.name,
        ehrVendor: config.organization.ehrSystem,
      },
    },
  };
  try {
    const result = await internalApi.orderAssistBff.supportedPersonCapabilities(input, source);
    return result;
  } catch (error) {
    return false;
  }
};
