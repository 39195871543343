import React, { useCallback, useEffect, useState } from 'react';
import { isUndefined } from 'lodash-es';
import { DisclaimerNote } from './DisclaimerNote';
import SelectOption from './SelectOption';
import './index.less';
import { logic } from '../../../logic';
import { WidgetSource, OrderAssistFormInputs } from '../../../types';
import { orderAssistLogger } from '../../../logger';

type Props = {
  source: WidgetSource | undefined;
  selectedInsurer?: string;
  selectedInsurancePlan?: string;
  onChange: (input: Partial<OrderAssistFormInputs>) => void;
};

export const SelectInsurancePlanInput: React.FC<Props> = ({
  source,
  selectedInsurer,
  selectedInsurancePlan,
  onChange,
}: Props) => {
  const [insurers, setInsurers] = useState<string[]>();
  const [insurancePlans, setInsurancePlans] = useState<string[]>();
  const [isPlansLoading, setIsPlansLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchInsurers = async () => {
      if (!source) {
        orderAssistLogger.warning('Failed to fetch insurers - source is required');
        return;
      }

      const result = await logic.getInsurers(source);
      setInsurers(result);
    };

    if (!insurers) {
      fetchInsurers();
    }
  }, [insurers, source]);

  const fetchPlansByInsurer = useCallback(
    async (insurer: string) => {
      if (!source) {
        orderAssistLogger.warning('Failed to insurance plans - source is required');
        return;
      }

      setIsPlansLoading(true);
      const plans = await logic.getPlansByInsurer(insurer, source);
      setInsurancePlans(plans);
      setIsPlansLoading(false);
    },
    [source],
  );

  useEffect(() => {
    setInsurancePlans(undefined);
    if (selectedInsurer) {
      fetchPlansByInsurer(selectedInsurer);
    } else {
      onChange({ insurancePlan: undefined });
    }
  }, [selectedInsurer, fetchPlansByInsurer, onChange]);

  useEffect(() => {
    if (
      selectedInsurancePlan &&
      insurancePlans &&
      !insurancePlans.includes(selectedInsurancePlan)
    ) {
      onChange({ insurancePlan: undefined });
    }
  }, [selectedInsurancePlan, insurancePlans, onChange, selectedInsurer, isPlansLoading]);

  const onInsurerChange = useCallback(
    (newInsurer) => {
      onChange({ insurer: newInsurer });
    },
    [onChange],
  );

  const onPlanChange = useCallback(
    (newPlan) => {
      onChange({ insurancePlan: newPlan });
    },
    [onChange],
  );

  return (
    <div>
      <DisclaimerNote />
      <div className="select-insurer-plan-input">
        <SelectOption
          value={selectedInsurer}
          items={insurers}
          onChange={onInsurerChange}
          wrapperProps={{
            className: 'insurer-input-wrapper',
            leftIcon: <i className="icon-shield" />,
          }}
          selectProps={{
            placeholder: 'Insurer',
            id: 'insurer-input',
            name: 'insurer',
            noDataLabel: 'No insurers found',
            noResultsLabel: 'No insurers found',
          }}
        />
        <SelectOption
          value={selectedInsurancePlan}
          items={insurancePlans}
          onChange={onPlanChange}
          loading={isPlansLoading}
          disabled={isUndefined(insurancePlans)}
          wrapperProps={{
            className: 'plan-input-wrapper',
          }}
          selectProps={{
            placeholder: 'Plan',
            id: 'plan-input',
            name: 'plan',
            noDataLabel: 'No plans found',
            noResultsLabel: 'No plans found',
          }}
        />
      </div>
    </div>
  );
};
