import { GetOrderAssistInput, WidgetSource } from '../../../types';
import {
  PreferredProvidersResult,
  GetPreferredProvidersResponse,
} from '../../../types/preferredProviders';
import { getFindQuery, getResults } from './formatters';
import { internalApi } from '../../../../../internal-api';
import { orderAssistLogger } from '../../../logger';

import { SearchMethod } from '../../../../../analytics/types';

export const getPreferred = async (
  input: GetOrderAssistInput & { shouldUseSourceVaultTokens: boolean },
  method: SearchMethod,
  source?: WidgetSource,
): Promise<PreferredProvidersResult> => {
  try {
    const query = getFindQuery(input);
    const response: GetPreferredProvidersResponse =
      await internalApi.orderAssistBff.getPreferredProviders(query, method, source);

    const preferredProviders = getResults({ results: response.results });
    orderAssistLogger.info('Get preferred providers Order Assist result', { preferredProviders });
    return {
      ...response,
      results: preferredProviders,
    };
  } catch (error) {
    orderAssistLogger.warning('Failed to get-preferred', { error });
    return {
      results: [],
      organizationHasPreferredProviders: false,
    };
  }
};
