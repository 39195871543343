import { useLazyQuery } from '@apollo/client';
import { useVimAuth } from '@getvim/internal-vim-os-sdk/react';
import { ProductSource, ProviderSource } from '@getvim-core-apps/organizations';
import { useEffect, useMemo, useState } from 'react';
import { UserConfig } from '../types';
import { widgetId } from '../../../consts';
import { AppConfig, VimSpecialtyMap } from '../../../components/app/types';
import { internalApi } from '../../../internal-api';
import { orderAssistLogger } from '../../../components/app/logger';
import { GET_APP_CONFIG_FOR_SDK } from '../../../internal-api/order-assist-bff/queries';
import { convertVimSpecialtyMap } from './vimSpecialty';

type SdkProductConfig = {
  productConfig: UserConfig['product'];
  vimSpecialtiesMap?: VimSpecialtyMap;
};

export const useSdkProductConfig = (): SdkProductConfig | undefined => {
  const appConfig = useAppBackendConfig();

  return useMemo(() => {
    if (!appConfig) return;

    const {
      contentSources: configSources,
      googleApiKey,
      vimSpecialtiesMap: vimSpecialtyTuples,
    } = appConfig;

    const contentSources: string[] = [];
    const sources: string[] = [];

    configSources?.map(({ dataSourceEnum, isInsurer }) => {
      contentSources.push(dataSourceEnum);
      if (isInsurer) {
        sources.push(dataSourceEnum);
      }
    });

    const productConfig = {
      name: widgetId,
      contentSources: contentSources as (ProductSource | ProviderSource)[],
      sources: sources as ProductSource[],
      googleApiKey,
    };

    const vimSpecialtiesMap = convertVimSpecialtyMap(vimSpecialtyTuples);

    return {
      productConfig,
      vimSpecialtiesMap,
    };
  }, [appConfig]);
};

const useAppBackendConfig = (): AppConfig | undefined => {
  const { accessToken } = useVimAuth();
  const [appConfig, setAppConfig] = useState<AppConfig>();

  const [getAppConfig, { data, error, loading }] = useLazyQuery(GET_APP_CONFIG_FOR_SDK, {
    fetchPolicy: 'no-cache',
    client: internalApi.orderAssistBff.getClientForSdk(),
  });

  useEffect(() => {
    if (!appConfig && accessToken) {
      getAppConfig();
    }
  }, [accessToken, appConfig, getAppConfig]);

  useEffect(() => {
    if (!loading) {
      if (error) {
        orderAssistLogger.warning('Failed to fetch getAppConfig query', { error });
      } else if (data) {
        setAppConfig(data.getAppConfig);
      }
    }
  }, [error, data, loading]);

  return useMemo(() => {
    return appConfig;
  }, [appConfig]);
};
