import { Toast } from '@getvim/atomic-ui';
import { WriteBacks } from '@getvim/vim-connect';
import { useCallback } from 'react';
import { trackSelectWithVim } from '../../../../../analytics';
import { orderAssistLogger } from '../../../logger';
import {
  AppState,
  OrderAssistFormData,
  OrderAssistOption,
  OrderAssistProviderOption,
  SelectActionMethod,
  SelectProviderAction,
  SelectProviderActionParams,
  SendOutcomeFunction,
  WriteBackProvider,
} from '../../../types';
import addressFormat from '../../../utils/addressFormatter';
import formatForClipboard from '../../../utils/clipboardFormatter';
import { isProviderResult } from '../../../utils/general';
import { parseOptionToWritebackProvider } from '../../../utils/providerWritebackFormatter';
import { getSelectedItemAddress } from '../../../utils/search/getSelectedAddress';
import { useClipboard } from './useClipboard';
import { useWriteBack } from './use-referral-writeback';

type UseProviderSelectionProps = {
  onSelectionFinally: (selectedProvider: WriteBackProvider, referralUpdated: boolean) => void;
  sendOutcomeProps: {
    sendOutcomeFn: SendOutcomeFunction;
    extraInfo: { distanceInfo: string | undefined };
  };
  searchFormData: OrderAssistFormData;
  referralViewedPayload: AppState['referral'];
  isReferralRequestRequired: AppState['isReferralRequestRequired'];
  onWriteBackStart: () => void;
  onWriteBackFailed: () => void;
  updateReferralAllowed: boolean;
  shouldUseSourceVaultTokens: boolean;
};

export type GetSelectProviderActionType = (provider: OrderAssistOption) => WriteBacks.SelectAction;

export interface UseProviderSelectionReturn {
  select: SelectProviderAction;
  getSelectionType: GetSelectProviderActionType;
}

const successMessages: Record<WriteBacks.SelectAction, string> = {
  [WriteBacks.SelectAction.COPY_TO_CLIPBOARD]:
    'The details were copied to the clipboard, you can now paste them to any text field',
  [WriteBacks.SelectAction.WRITEBACK]: 'Your EHR was updated based on your selection',
};

interface OnSelectionFinallyDefaultOptions {
  useToast?: boolean;
  useAnalytics?: boolean;
}
interface OnSelectionFinallyDefaultParams {
  success: boolean;
  error: string;
  selectionType: WriteBacks.SelectAction;
  provider: OrderAssistOption;
  actionMethod: SelectActionMethod;
  isAlternative?: boolean;
  isPreferred?: boolean;
}

interface OnFinishSelectionAnalyticsParams {
  provider: OrderAssistOption;
  selectionType: WriteBacks.SelectAction;
  selectedAddress: string;
  acceptNewPatients?: boolean;
  isAlternative: boolean;
  isPreferred: boolean;
  actionMethod: SelectActionMethod;
}

const onFinishSelectionToast = async ({
  success,
  selectionType,
  error,
}: {
  success: boolean;
  selectionType: WriteBacks.SelectAction;
  error?: string;
}): Promise<void> => {
  const { createToast, ToastTypes } = Toast;

  const doneTitle = 'All done!';
  if (success) {
    createToast({
      title: doneTitle,
      message: successMessages[selectionType],
      type: ToastTypes.SUCCESS,
      html: true,
    });
  } else {
    const isProviderNotFoundWarn = error === 'ProviderNotFoundError';

    orderAssistLogger.info('Calculated isProviderNotFoundWarn', {
      noPHI: true,
      isProviderNotFoundWarn,
      error,
    });

    const title = isProviderNotFoundWarn ? doneTitle : 'Oops';
    const message = isProviderNotFoundWarn
      ? `Selected provider doesn't exist in your EHR \n` +
        `The details will appear in the notes section`
      : 'Something went wrong';
    const type = isProviderNotFoundWarn ? ToastTypes.WARNING : ToastTypes.ERROR;

    createToast({
      title,
      message,
      type,
      html: true,
    });
  }
};

export const useProviderSelection = ({
  onSelectionFinally,
  referralViewedPayload,
  isReferralRequestRequired,
  sendOutcomeProps,
  searchFormData,
  onWriteBackStart,
  onWriteBackFailed,
  updateReferralAllowed,
  shouldUseSourceVaultTokens,
}: UseProviderSelectionProps): UseProviderSelectionReturn => {
  const { writeProvider } = useWriteBack();
  const copy = useClipboard();
  const { extraInfo, sendOutcomeFn } = sendOutcomeProps;

  const getSelectionType: GetSelectProviderActionType = useCallback(
    (provider) => {
      return updateReferralAllowed && isProviderResult(provider)
        ? WriteBacks.SelectAction.WRITEBACK
        : WriteBacks.SelectAction.COPY_TO_CLIPBOARD;
    },
    [updateReferralAllowed],
  );

  const onFinishSelectionAnalytics = useCallback(
    (success: boolean, params: OnFinishSelectionAnalyticsParams) => {
      const {
        isAlternative,
        isPreferred,
        provider,
        selectedAddress,
        acceptNewPatients,
        selectionType,
        actionMethod,
      } = params;
      trackSelectWithVim({
        selectAction: selectionType,
        status: success ? WriteBacks.ActionStatus.SUCCESS : WriteBacks.ActionStatus.FAILED,
        isAlternativeResult: isAlternative,
        selectedOption: provider,
        selectedAddress,
        acceptNewPatients,
        previousProvider: referralViewedPayload?.targetProvider,
        isPreferredResult: isPreferred,
        isReferralRequestRequired,
        method: actionMethod,
      });
    },
    [isReferralRequestRequired, referralViewedPayload?.targetProvider],
  );

  const onSelectionFinallyDefault = useCallback(
    async (
      params: OnSelectionFinallyDefaultParams,
      options?: OnSelectionFinallyDefaultOptions | undefined,
    ) => {
      const {
        success,
        provider,
        selectionType,
        isAlternative = false,
        isPreferred = false,
        actionMethod,
        error,
      } = params;

      const selectedItemAddress = getSelectedItemAddress(provider);
      const selectedAddress = addressFormat(selectedItemAddress);

      const { acceptNewPatients } = selectedItemAddress;

      if (options?.useToast) await onFinishSelectionToast({ success, error, selectionType });
      if (options?.useAnalytics)
        onFinishSelectionAnalytics(success, {
          isAlternative,
          isPreferred,
          provider,
          selectedAddress,
          acceptNewPatients,
          selectionType,
          actionMethod,
        });
      sendOutcomeFn({
        item: provider,
        isAlternative,
        selectedLocation: getSelectedItemAddress(provider),
        currentSearchState: searchFormData,
        extraInfo,
        shouldUseSourceVaultTokens,
      });
    },
    [extraInfo, onFinishSelectionAnalytics, searchFormData, sendOutcomeFn],
  );

  const select: SelectProviderAction = useCallback(
    async ({ provider, isAlternative, isPreferred, actionMethod }: SelectProviderActionParams) => {
      let success = false;
      let error;
      let isReferralUpdated = false;
      const selectionType = getSelectionType(provider);
      if (!selectionType) return { success: false, selectionType };

      const writeBackProvider: WriteBackProvider = parseOptionToWritebackProvider(
        provider as OrderAssistProviderOption,
      );

      try {
        switch (selectionType) {
          case WriteBacks.SelectAction.COPY_TO_CLIPBOARD: {
            const text = formatForClipboard(provider);

            success = await copy(text);
            break;
          }
          case WriteBacks.SelectAction.WRITEBACK: {
            onWriteBackStart();
            const result = await writeProvider(writeBackProvider);
            success = result.success;
            error = result?.error;

            if (!success) {
              onWriteBackFailed();
            }

            isReferralUpdated = true;

            break;
          }
          default: {
            orderAssistLogger.warning('Got an unexpected selection type!', { selectionType });
            break;
          }
        }
      } catch (error) {
        orderAssistLogger.warning('Error occurred when selecting provider', {
          selectionType,
          provider,
          error,
        });
      } finally {
        await onSelectionFinallyDefault(
          { success, provider, selectionType, isAlternative, isPreferred, actionMethod, error },
          { useAnalytics: true, useToast: true },
        );
        onSelectionFinally(writeBackProvider, isReferralUpdated);
      }
      return { selectionType, success };
    },
    [
      getSelectionType,
      copy,
      onWriteBackStart,
      writeProvider,
      onWriteBackFailed,
      onSelectionFinallyDefault,
      onSelectionFinally,
    ],
  );

  return { select, getSelectionType };
};
