import { Loader, Text } from '@getvim/atomic-ui';
import { useFeatureFlag } from '@getvim/feature-flags-react';
import { useFlowState } from '@getvim/flow-context-provider';
import { Entities } from '@getvim/vim-connect';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { sendBackToSearchAnalytic } from '../../../../../analytics/analytics';
import { exportAnalyticsClient } from '../../../../../analytics/clients/exportAnalytics.client';
import { trackReferralRequestStartScreenViewed } from '../../../../../analytics/referral-request';
import {
  ExportMenuOption,
  OrderAssistScreenName,
  SearchMethod,
} from '../../../../../analytics/types';
import { ExportAnalyticsEventNames } from '../../../../../analytics/types/exportAnalytics.types';
import TryingAgain from '../../../../../assets/trying-again.svg';
import { referralRequestFlowDelayMs } from '../../../../../consts';
import { useEhrState } from '../../../../../stores/ehr-state/EhrState.store';
import { getDefaultSearchFilters, getLongestDistance } from '../../../consts/searchFilters';
import { orderAssistLogger } from '../../../logger';
import { downloadResultsPDF } from '../../../logic/actions';
import { resultsPDFFormatters } from '../../../logic/actions/export/results.formatter';
import { DataFilters, ScreenName } from '../../../types';
import getCostRequestStatus from '../../../utils/cost';
import { SearchRequiredState } from '../../SourceConfigWrapper';
import { CustomAction, QuickSpecialty } from '../../filters/QuickSpecialty';
import { DownloadState } from '../../modals/DownloadModal';
import { PreferredProvidersProps } from '../../order-assist-app/hooks';
import useCopyResultsToClipboard from '../../order-assist-app/hooks/useCopyResultsToClipboard';
import { ReferralRequestContainer } from '../../referral-request';
import ImageMessageView from '../components/ImageMessageView';
import NoResultsWithDistance from '../components/NoResultsWithDistance';
import SearchError from '../components/SearchError';
import { TitleBar } from '../components/Titlebar';
import { pdfDownloadStates } from '../components/PdfDownloadStates';
import { formatSearchDetails } from '../formatSearchDetails';
import '../index.less';
import { SearchResults } from '../results';
import { SearchResultsContainerProps } from './types';
import { QuickSpecialtyTypes } from '../../search-form/types';
import { RESET_SPECIALTY } from '../../filters/specialties.consts';
import { isEmpty } from 'lodash-es';

export const SearchResultsContainer: React.FC<SearchResultsContainerProps> = ({
  results,
  benefits,
  disclaimers,
  searchFormData,
  formData,
  filtersData,
  onFiltersChanged,
  costAvailable,
  onSearch,
  onFreeTextQuery,
  backToSearch,
  backToResultsScreen,
  resetSearchForm,
  loading,
  resultsError,
  referralViewedPayload,
  sendOutcome,
  onProviderSelect,
  patient,
  preferredProviders,
  displayPreferredProvidersListFF,
  organizationHasPreferredProviders,
  getSelectionType,
  selectedProvider,
  source,
  isReferralRequestRequired,
  onProviderAddressChange,
  isModifiedSearch,
  shouldModifySearch,
  isReadonlyMode = false,
  shouldUpdateReferral,
  onQuickSpecialtyChange,
  useDiQuickSpecialtyFF,
  useReferralQuickSpecialtyFF,
}) => {
  const [searchDetails, setSearchDetails] = useState<string>('');
  const { sourceConfig, userConfig } = useFlowState<SearchRequiredState>();
  const { supportLanguage, supportCost, supportWiderDistanceFilter, defaultDistanceFilter } =
    sourceConfig;

  const { designatedDiagnosticProvider, placeOfServiceDifferential } = benefits || {};
  const [isInReferralRequest, setIsInReferralRequest] = useState<boolean>(false);
  const copyResultsToClipboard = useCopyResultsToClipboard();

  const longestDistance = useMemo(
    () => getLongestDistance(supportWiderDistanceFilter),
    [supportWiderDistanceFilter],
  );
  const defaultSearchFilter = useMemo(
    () => getDefaultSearchFilters(supportWiderDistanceFilter, defaultDistanceFilter),
    [supportWiderDistanceFilter, defaultDistanceFilter],
  );

  const [noCostTransparencyError] = useFeatureFlag({
    flagName: 'noCostTransparencyError',
    defaultValue: false,
  });

  const [shouldImproveCopyExportSearchResults] = useFeatureFlag({
    flagName: 'shouldImproveCopyExportSearchResults',
    defaultValue: false,
  });

  const [useSelectedLocationOfProvider] = useFeatureFlag({
    defaultValue: false,
    flagName: 'useSelectedLocationOfProvider',
  });

  const { state } = useEhrState();

  useEffect(() => {
    setSearchDetails(
      formatSearchDetails({
        searchData: searchFormData,
        patient,
      }),
    );
  }, [patient, searchFormData]);

  const handleSearch = useCallback(
    ({
      searchPage,
      filters,
      ehrNativeSpecialty,
      method,
      preferredProvidersDetails = { fetchPreferredProviders: true },
      applyModifiedSearch = false,
    }: {
      searchPage?: number;
      filters?: DataFilters;
      ehrNativeSpecialty?: string;
      method?: SearchMethod;
      preferredProvidersDetails?: PreferredProvidersProps;
      applyModifiedSearch?: boolean;
    }) => {
      if (isEmpty(searchFormData)) {
        orderAssistLogger.warning('searchFormData is empty. skipping search');
        return;
      }
      onSearch({
        searchFormData,
        filtersData: { ...filters, lastModifiedFromScreen: ScreenName.OrderAssistResults },
        ehrNativeSpecialty,
        page: searchPage,
        method,
        preferredProviders: preferredProvidersDetails,
        isModifiedSearch: applyModifiedSearch,
      });
      window.scrollTo(0, 0);
    },
    [onSearch, searchFormData],
  );

  const handleSearchPageChanged = (pageNumber: number) => {
    handleSearch({
      searchPage: pageNumber,
      method: SearchMethod.CHANGE_PAGE,
      preferredProvidersDetails: {
        fetchPreferredProviders: false,
        currentCount: preferredProviders.length,
      },
      filters: filtersData,
      ehrNativeSpecialty: referralViewedPayload?.ehrNativeSpecialty,
    });
  };

  const handleFiltersChanged = useCallback(
    (newFilters: DataFilters, applyModifiedSearch?: boolean, method?: SearchMethod) => {
      onFiltersChanged(newFilters);
      handleSearch({
        filters: newFilters,
        ehrNativeSpecialty: referralViewedPayload?.ehrNativeSpecialty,
        applyModifiedSearch,
        method,
      });
    },
    [onFiltersChanged, handleSearch, referralViewedPayload?.ehrNativeSpecialty],
  );

  const withBenefitsBar = !![designatedDiagnosticProvider, placeOfServiceDifferential].filter(
    Boolean,
  ).length;

  const costResultStatus = getCostRequestStatus(
    costAvailable,
    results,
    !supportCost,
    noCostTransparencyError,
  );

  const hasResults = !!results?.searchResults?.length || !!results?.alternativeResults?.length;

  useEffect(() => {
    if (isInReferralRequest && selectedProvider) {
      trackReferralRequestStartScreenViewed();
    }
  }, [isInReferralRequest, selectedProvider]);

  useEffect(() => {
    if (!selectedProvider || !isReferralRequestRequired) return setIsInReferralRequest(false);

    const timer = setTimeout(() => {
      setIsInReferralRequest(true);
    }, referralRequestFlowDelayMs);

    return () => clearTimeout(timer);
  }, [selectedProvider, isReferralRequestRequired]);

  const backToResults = useCallback(() => {
    backToResultsScreen();
    sendBackToSearchAnalytic();
  }, [backToResultsScreen]);

  useEffect(() => {
    if (!shouldModifySearch) return;

    handleFiltersChanged(
      {
        resultsFilters: {
          distance: longestDistance,
          language: filtersData?.resultsFilters?.language ?? defaultSearchFilter.language,
        },
        userFilters: filtersData?.userFilters ?? defaultSearchFilter,
      },
      true,
      SearchMethod.AUTOMATIC,
    );

  }, [
    loading,
    filtersData,
    handleFiltersChanged,
    longestDistance,
    hasResults,
    patient,
    referralViewedPayload?.ehrNativeSpecialty,
    searchFormData.insurancePlan,
    searchFormData.insurer,
    searchFormData.specialty,
    supportWiderDistanceFilter,
    defaultSearchFilter,
    shouldModifySearch,
  ]);

  const shouldDisplayNoResults = useMemo(
    () => !hasResults && !resultsError && !shouldModifySearch,
    [hasResults, resultsError, shouldModifySearch],
  );

  const exportCopyResultsAction = async (): Promise<boolean> => {
    if (!results) return true;
    const { success } = await copyResultsToClipboard(
      results,
      displayPreferredProvidersListFF,
      preferredProviders,
    );
    return !!success;
  };

  const createResultsPDFAction = async (): Promise<boolean> => {
    const success = await downloadResultsPDF(
      resultsPDFFormatters.formatInput(
        shouldImproveCopyExportSearchResults,
        userConfig,
        patient,
        results?.searchResults,
        displayPreferredProvidersListFF ? preferredProviders : undefined,
        results?.alternativeResults,
      ),
      resultsPDFFormatters.formatFilename(patient),
    );
    return success;
  };

  const onCopyActionStart = useCallback(() => {
    exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_DROPDOWN_SELECTED, {
      option: ExportMenuOption.COPY,
    });
  }, []);

  const onCopyActionSuccess = useCallback(
    () =>
      exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED, {
        action_type: ExportMenuOption.COPY,
        status: 'successful',
      }),
    [],
  );

  const onCopyActionError = useCallback(
    () =>
      exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED, {
        action_type: ExportMenuOption.COPY,
        status: 'failed',
      }),
    [],
  );

  const [PDFState, setPDFState] = useState<DownloadState>();

  const onPDFStart = useCallback(() => {
    setPDFState(pdfDownloadStates.loading);
    exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_DROPDOWN_SELECTED, {
      option: ExportMenuOption.DOWNLOAD,
    });
  }, []);

  const onPDFSuccess = useCallback(() => {
    setPDFState(pdfDownloadStates.success);
    exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED, {
      action_type: ExportMenuOption.DOWNLOAD,
      status: 'successful',
    });
  }, []);

  const onPDFError = useCallback(() => {
    setPDFState(pdfDownloadStates.error);
    exportAnalyticsClient.track(ExportAnalyticsEventNames.EXPORT_MENU_ACTION_COMPLETED, {
      action_type: ExportMenuOption.DOWNLOAD,
      status: 'failed',
    });
  }, []);

  const onPDFFinish = useCallback(
    (success: boolean) => {
      if (success) {
        onPDFSuccess();
      } else {
        onPDFError();
      }
    },
    [onPDFError, onPDFSuccess],
  );

  const onExportModalClose = () => {
    setPDFState(undefined);
  };

  const quickSpecialtyConfig: { type: QuickSpecialtyTypes; actions?: CustomAction[] } | null =
    useMemo(() => {
      if (state?.order?.type === Entities.OrderType.DI && useDiQuickSpecialtyFF) {
        return { type: QuickSpecialtyTypes.DI };
      } else if (state?.referral && useReferralQuickSpecialtyFF) {
        return {
          type: QuickSpecialtyTypes.REFERRAL,
          actions: [{ ...RESET_SPECIALTY, onSelect: backToSearch }],
        };
      }
      return null;
    }, [
      state?.referral,
      state?.order,
      useReferralQuickSpecialtyFF,
      useDiQuickSpecialtyFF,
      backToSearch,
    ]);

  return isInReferralRequest && selectedProvider ? (
    <ReferralRequestContainer
      onFreeTextQuery={onFreeTextQuery}
      backToSearch={backToSearch}
      backToResults={backToResults}
      referralViewedPayload={referralViewedPayload}
      selectedProvider={selectedProvider}
      patient={patient}
      source={source}
      resetSearchForm={resetSearchForm}
      shouldUpdateReferral={shouldUpdateReferral}
      titleBarProps={{
        searchDetails,
        screenName: OrderAssistScreenName.REFERRAL_REQUEST_STATUS,
        results,
        benefits,
        handleFiltersChanged,
        searchFilters: filtersData?.resultsFilters,
        supportLanguage,
        supportWiderDistanceFilter,
        defaultDistanceFilter,
        withBenefitsBar,
        hideFilters: true,
      }}
    />
  ) : (
    <div className="search-results-container">
      <div className="search-results-container__title">
        <TitleBar
          searchDetails={searchDetails}
          screenName={OrderAssistScreenName.SEARCH_RESULTS}
          onClick={backToSearch}
          benefits={benefits}
          handleFiltersChanged={handleFiltersChanged}
          searchFilters={filtersData?.resultsFilters}
          supportLanguage={supportLanguage}
          supportWiderDistanceFilter={supportWiderDistanceFilter}
          defaultDistanceFilter={defaultDistanceFilter}
          withBenefitsBar={withBenefitsBar}
          results={results}
          dropdownMenuItems={[
            {
              text: 'Copy',
              value: ExportMenuOption.COPY,
              icon: 'icon-copy',
              onClick: exportCopyResultsAction,
              onClickCallback: {
                onStart: onCopyActionStart,
                onSuccess: onCopyActionSuccess,
                onError: onCopyActionError,
              },
            },
            {
              text: 'Download',
              value: ExportMenuOption.DOWNLOAD,
              icon: 'icon-export-pdf',
              onClick: createResultsPDFAction,
              onClickCallback: {
                onStart: onPDFStart,
                onFinish: onPDFFinish,
              },
            },
          ]}
          onExportModalClose={onExportModalClose}
          downloadState={PDFState}
          isReadonlyMode={isReadonlyMode}
        />
      </div>
      {quickSpecialtyConfig && (
        <QuickSpecialty
          type={quickSpecialtyConfig.type}
          value={formData}
          onChange={onQuickSpecialtyChange}
          customActions={quickSpecialtyConfig.actions}
        />
      )}
      <div
        className={classNames('search-results-container__results', {
          'no-results': !results?.searchResults?.length && !results?.alternativeResults?.length,
        })}
      >
        {loading ? (
          <>
            {isModifiedSearch ? (
              <div>
                <ImageMessageView
                  imageSrc={TryingAgain}
                  title="No results found"
                  details="We are increasing the distance criteria to expand the search"
                />
                <Loader type="dots" className="modify-search-loader" />
                <Text>This may take a few seconds</Text>
              </div>
            ) : (
              <Loader type="dots" className="search-results-loader" />
            )}
          </>
        ) : (
          <>
            {hasResults && (
              <SearchResults
                source={source}
                results={results}
                costResultStatus={costResultStatus}
                currentSearchState={searchFormData}
                disclaimers={disclaimers}
                getSelectionType={getSelectionType}
                referralViewedPayload={referralViewedPayload}
                onProviderSelect={onProviderSelect}
                onProviderAddressChange={onProviderAddressChange}
                sendOutcome={sendOutcome}
                changePage={handleSearchPageChanged}
                preferredProviders={preferredProviders}
                displayPreferredProvidersListFF={displayPreferredProvidersListFF}
                organizationHasPreferredProviders={organizationHasPreferredProviders}
                goBackAction={backToSearch}
                isReferralRequestRequired={isReferralRequestRequired ?? false}
                maxSearchDistance={isModifiedSearch ? longestDistance : undefined}
                isReadonlyMode={isReadonlyMode}
                useSelectedLocationOfProvider={useSelectedLocationOfProvider}
              />
            )}

            {shouldDisplayNoResults && (
              <NoResultsWithDistance
                onNewSearch={backToSearch}
                searchDistance={
                  filtersData?.resultsFilters?.distance ?? defaultSearchFilter.distance
                }
                patientName={`${patient?.demographics.firstName} ${patient?.demographics.lastName}`}
                zipCode={searchFormData.address?.zipCode || ''}
              />
            )}

            {resultsError && (
              <SearchError
                onTryAgain={() =>
                  handleSearch({
                    filters: filtersData,
                    ehrNativeSpecialty: referralViewedPayload?.ehrNativeSpecialty,
                  })
                }
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
