import { capitalize } from 'lodash-es';
import {
  OrderAssistFacilityOption,
  OrderAssistOption,
  OrderAssistOptionType,
  OrderAssistProviderOption,
} from '../types';
import { orderAssistLogger } from '../logger';

export const capitalizeAsName = (str: string): string => {
  let capitalizedString = '';

  try {
    capitalizedString = str
      ?.split(' ')
      .map((word) => capitalize(word.toString()))
      .join(' ');
  } catch (error) {
    return str?.toString();
  }

  return capitalizedString;
};

export const isProviderResult = (
  result: OrderAssistOption,
): result is OrderAssistProviderOption => {
  return result.type === OrderAssistOptionType.Provider;
};

export const isFacilityResult = (
  result: OrderAssistOption,
): result is OrderAssistFacilityOption => {
  return result.type === OrderAssistOptionType.Facility;
};

export const sumLengthOfArrays = <T>(arrays: (T[] | undefined)[]): number => {
  return arrays.reduce((counter, array) => counter + (array ? array.length : 0), 0);
};
